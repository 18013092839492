<script setup lang="ts"></script>

<template>
    <svg
        width="7"
        height="14"
        viewBox="0 0 7 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.70908 1.93191C7.09697 1.48996 7.09697 0.773416 6.70908 0.331464C6.32118 -0.110488 5.69227 -0.110488 5.30437 0.331464L0.387899 5.93303C-0.1293 6.5223 -0.1293 7.4777 0.387899 8.06697L5.30437 13.6685C5.69227 14.1105 6.32118 14.1105 6.70908 13.6685C7.09697 13.2266 7.09697 12.51 6.70908 12.0681L3.19731 8.06697C2.68011 7.4777 2.68011 6.5223 3.19731 5.93303L6.70908 1.93191Z"
            fill="currentColor"
        />
    </svg>
</template>
